export default [
  {
    path: '/twitch/giveaways',
    name: 'TwitchGiveaways',
    component: () => import('@/views/Admin/Twitch/Giveaways/Giveaways.vue'),
    meta: {
      pageTitle: 'Twitch',
      breadcrumb: [
        {
          text: 'Çekilişler',
          active: true,
        },
      ],
      resource: 'ADMIN_TWITCH',
      action: 'read',
    },
  },
  {
    path: '/twitch/giveaways/list',
    name: 'TwitchGiveawayList',
    component: () => import('@/views/Admin/Twitch/Giveaways/Index.vue'),
    meta: {
      pageTitle: 'Twitch',
      breadcrumb: [
        {
          to: '/twitch/giveaways',
          text: 'Çekilişler',
        },
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_TWITCH',
      action: 'read',
    },
  },
  {
    path: '/twitch/giveaways/view/:id',
    name: 'TwitchGiveawayList',
    component: () => import('@/views/Admin/Twitch/Giveaways/View.vue'),
    meta: {
      pageTitle: 'Twitch',
      breadcrumb: [
        {
          to: '/twitch/giveaways',
          text: 'Çekilişler',
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'ADMIN_TWITCH',
      action: 'read',
    },
  },
]
