export default [
  {
    path: '/app/social-media',
    name: 'Socialmedia',
    component: () => import('@/views/Admin/Socialmedia/Index.vue'),
    meta: {
      pageTitle: 'Sosyal Medya',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_MODULES',
      action: 'read',
    },
  },
  {
    path: '/app/social-media/add',
    name: 'SocialmediaAdd',
    component: () => import('@/views/Admin/Socialmedia/Add.vue'),
    meta: {
      pageTitle: 'Sosyal Medya',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/social-media',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_MODULES',
      action: 'read',
    },
  },
  {
    path: '/app/social-media/edit/:id',
    name: 'SocialmediaEdit',
    component: () => import('@/views/Admin/Socialmedia/Edit.vue'),
    meta: {
      pageTitle: 'Sosyal Medya',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/social-media',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_MODULES',
      action: 'read',
    },
  },
]
