import Vue from 'vue'
import VueRouter from 'vue-router'

import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
/* CORE */
import app from './routes/app'
/* ADMIN:CORE */
import users from './routes/Admin/Core/Users'
import userTypes from './routes/Admin/Core/User_types'
import smtpConfig from './routes/Admin/Core/Configs'
/* ADMIN:DEFINES */
/* ADMIN:APP */
import banners from './routes/Admin/App/Banners'
import buttons from './routes/Admin/App/Buttons'
import components from './routes/Admin/App/Components'
import configs from './routes/Admin/App/Configs'
import contents from './routes/Admin/App/Contents'
import events from './routes/Admin/App/Events'
import links from './routes/Admin/App/Links'
import socialMedia from './routes/Admin/App/Socialmedia'
import welcome from './routes/Admin/App/Welcome'
import twitchApiConfig from './routes/Admin/App/Twitch_api_config'

/* TWITCH */
import twitch from './routes/Admin/App/Twitch'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'home' } },
    ...app,
    ...configs,
    ...users,
    ...userTypes,
    ...banners,
    ...buttons,
    ...components,
    ...contents,
    ...events,
    ...links,
    ...smtpConfig,
    ...socialMedia,
    ...welcome,
    ...twitchApiConfig,
    ...twitch,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
