export default [
  {
    path: '/app/welcome',
    name: 'Welcome',
    component: () => import('@/views/Admin/Welcome/Index.vue'),
    meta: {
      pageTitle: 'Anasayfa',
      breadcrumb: [
        {
          text: 'Karşılama',
          active: true,
        },
      ],
      resource: 'ADMIN_CMS',
      action: 'read',
    },
  },
]
