export default [
  {
    path: '/app/buttons',
    name: 'Buttons',
    component: () => import('@/views/Admin/Buttons/Index.vue'),
    meta: {
      pageTitle: 'Giriş Bağlantıları',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_CMS',
      action: 'read',
    },
  },
  {
    path: '/app/buttons/add',
    name: 'ButtonsAdd',
    component: () => import('@/views/Admin/Buttons/Add.vue'),
    meta: {
      pageTitle: 'Giriş Bağlantıları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/buttons',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_CMS',
      action: 'read',
    },
  },
  {
    path: '/app/buttons/edit/:id',
    name: 'ButtonsEdit',
    component: () => import('@/views/Admin/Buttons/Edit.vue'),
    meta: {
      pageTitle: 'Giriş Bağlantıları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/buttons',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_CMS',
      action: 'read',
    },
  },
]
