import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/data/twitch_api_config'
export default {
  namespaced: true,
  state: {
    dataItem: {
      id: null,
      client_id: null,
      client_secret: null,
      channel_name: null,
      redirect_url: null,
      access_token: null,
      user_access_token: null,
      id_users: null,
    },
    userAccessTokenURl: null,
    accessTokenStatus: {
      message: null,
      status: null,
      access_token: null,
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    accessTokenStatus(state) {
      return state.accessTokenStatus
    },
    getUserAccessTokenUrl(state) {
      return state.userAccessTokenURl
    },
  },
  mutations: {
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_ACCESS_TOKEN(state, data) {
      state.accessTokenStatus = data
    },
    SET_USER_ACCESS_TOKEN_URL(state, data) {
      state.userAccessTokenURl = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        id: null,
        client_id: null,
        client_secret: null,
        channel_name: null,
        redirect_url: null,
        access_token: null,
        user_access_token: null,
        id_users: null,
      }
    },
    RESET_ACCESS_TOKEN(state) {
      state.accessTokenStatus = {
        message: null,
        status: null,
        access_token: null,
      }
    },
  },
  actions: {
    getDataItem({ commit }) {
      commit('RESET_DATA_ITEM')
      return axiosIns
        .post(ROOT_URL)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    getAccessToken({ commit }) {
      commit('RESET_ACCESS_TOKEN')
      return axiosIns
        .post(`${ROOT_URL}/getAccessToken`)
        .then(response => {
          commit('SET_ACCESS_TOKEN', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    setUserAccessTokenUrl({ commit }) {
      commit('RESET_ACCESS_TOKEN')
      return axiosIns
        .post(`${ROOT_URL}/getUserAccessTokenURL`)
        .then(response => {
          commit('SET_USER_ACCESS_TOKEN_URL', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post(`${ROOT_URL}/saveData`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
