import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/data/configs'
export default {
  namespaced: true,
  state: {
    dataItem: {
      id: null,
      footer_text: null,
      analytics: null,
      facebook_pixel: null,
      search_console_meta: null,
      tag_manager_head: null,
      tag_manager_body: null,
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
  },
  mutations: {
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        id: null,
        footer_text: null,
        analytics: null,
        facebook_pixel: null,
        search_console_meta: null,
        tag_manager_head: null,
        tag_manager_body: null,
      }
    },
  },
  actions: {
    getDataItem({ commit }) {
      commit('RESET_DATA_ITEM')
      axiosIns
        .post(ROOT_URL)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post(`${ROOT_URL}/saveData`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
