import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/core/cacheClean'
export default {
  namespaced: true,
  state: {
    requestResult: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    requestResult(state) {
      return state.requestResult
    },
  },
  mutations: {
    SET_RESULT(state, data) {
      state.requestResult = data
    },
  },
  actions: {
    purgeCache({ commit }) {
      return axiosIns
        .post(`${ROOT_URL}`)
        .then(response => {
          commit('SET_RESULT', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}
