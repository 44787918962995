import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/data/twitch'
export default {
  namespaced: true,
  state: {
    giveAways: {
      users: [],
      giveAwayers: [],
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getGiveAways(state) {
      return state.giveAways
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
  },
  mutations: {
    SET_GIVE_AWAYS(state, data) {
      state.giveAways.users = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
  },
  actions: {
    giveAways({ commit }, postData) {
      const data = JSON.stringify(postData)
      return axiosIns
        .post(`${ROOT_URL}/giveAways`, data)
        .then(response => {
          commit('SET_GIVE_AWAYS', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
  },
}
