export default [
  {
    path: '/app/events',
    name: 'Events',
    component: () => import('@/views/Admin/Events/Index.vue'),
    meta: {
      pageTitle: 'Etkinlikler',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_NEWS',
      action: 'read',
    },
  },
  {
    path: '/app/events/add',
    name: 'EventsAdd',
    component: () => import('@/views/Admin/Events/Add.vue'),
    meta: {
      pageTitle: 'Etkinlikler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/events',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_NEWS',
      action: 'read',
    },
  },
  {
    path: '/app/events/edit/:id',
    name: 'EventsEdit',
    component: () => import('@/views/Admin/Events/Edit.vue'),
    meta: {
      pageTitle: 'Etkinlikler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/events',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_NEWS',
      action: 'read',
    },
  },
]
