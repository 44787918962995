export default [
  {
    path: '/app/banners',
    name: 'Banners',
    component: () => import('@/views/Admin/Banners/Index.vue'),
    meta: {
      pageTitle: 'Reklam Banner',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_CMS',
      action: 'read',
    },
  },
  {
    path: '/app/banners/add',
    name: 'BannersAdd',
    component: () => import('@/views/Admin/Banners/Add.vue'),
    meta: {
      pageTitle: 'Reklam Banner',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/banners',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_CMS',
      action: 'read',
    },
  },
  {
    path: '/app/banners/edit/:id',
    name: 'BannersEdit',
    component: () => import('@/views/Admin/Banners/Edit.vue'),
    meta: {
      pageTitle: 'Reklam Banner',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/banners',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_CMS',
      action: 'read',
    },
  },
]
