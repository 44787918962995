import Vue from 'vue'
import Vuex from 'vuex'

/* Theme Core */
import verticalMenu from './vertical-menu'
import appConfig from './app-config'
import app from './app'
/* App Core */
import users from './Admin/Core/users'
import userTypes from './Admin/Core/user_types'
import smtpConfig from './Admin/Core/smtpconfig'
import abilities from './Admin/Core/abilities'
import cropperUploads from './Admin/Core/cropper_uploads'
import fileUpload from './Admin/Core/file_upload'
import cacheClean from './Admin/Core/cacheClean'
/* App Data */
import banners from './Admin/App/banners'
import buttons from './Admin/App/buttons'
import components from './Admin/App/components'
import contents from './Admin/App/contents'
import events from './Admin/App/events'
import giveaways from './Admin/App/giveaways'
import links from './Admin/App/links'
import siteConfig from './Admin/App/site_config'
import socialMedia from './Admin/App/socialmedia'
import welcome from './Admin/App/welcome'
import twitchConfigApi from './Admin/App/twitch_api_config'
import twitch from './Admin/App/twitch'
/* App Defines */

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    users,
    userTypes,
    smtpConfig,
    abilities,
    cropperUploads,
    fileUpload,
    cacheClean,
    banners,
    buttons,
    components,
    contents,
    events,
    giveaways,
    links,
    socialMedia,
    siteConfig,
    welcome,
    twitchConfigApi,
    twitch,
  },
  strict: process.env.DEV,
})
