export default [
  {
    path: '/app/contents',
    name: 'Contents',
    component: () => import('@/views/Admin/Contents/Index.vue'),
    meta: {
      pageTitle: 'Sabit İçerikler',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_CONTENTS',
      action: 'read',
    },
  },
  {
    path: '/app/contents/add',
    name: 'ContentsAdd',
    component: () => import('@/views/Admin/Contents/Add.vue'),
    meta: {
      pageTitle: 'Sabit İçerikler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/contents',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_CONTENTS',
      action: 'read',
    },
  },
  {
    path: '/app/contents/edit/:id',
    name: 'ContentsEdit',
    component: () => import('@/views/Admin/Contents/Edit.vue'),
    meta: {
      pageTitle: 'Sabit İçerikler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/contents',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_CONTENTS',
      action: 'read',
    },
  },
]
