export default [
  {
    path: '/app/links',
    name: 'Links',
    component: () => import('@/views/Admin/Links/Index.vue'),
    meta: {
      pageTitle: 'Bağlantılar',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_CMS',
      action: 'read',
    },
  },
  {
    path: '/app/links/add',
    name: 'LinksAdd',
    component: () => import('@/views/Admin/Links/Add.vue'),
    meta: {
      pageTitle: 'Bağlantılar',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/links',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_CMS',
      action: 'read',
    },
  },
  {
    path: '/app/links/edit/:id',
    name: 'LinksEdit',
    component: () => import('@/views/Admin/Links/Edit.vue'),
    meta: {
      pageTitle: 'Bağlantılar',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/links',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_CMS',
      action: 'read',
    },
  },
]
