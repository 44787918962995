export default [
  {
    path: '/twitch-api-config',
    name: 'TwitchApiConfig',
    component: () => import('@/views/Admin/Twtich_api_config/Index.vue'),
    meta: {
      pageTitle: 'Twitch Entegrasyon Bilgileri',
      breadcrumb: [
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'ADMIN_CONFIG',
      action: 'read',
    },
  },
]
