export default [
  {
    path: '/app/components',
    name: 'Components',
    component: () => import('@/views/Admin/Components/Index.vue'),
    meta: {
      pageTitle: 'Ayarlar',
      breadcrumb: [
        {
          text: 'Seo Yapılandırma',
          active: true,
        },
      ],
      resource: 'ADMIN_COMPONENTS',
      action: 'read',
    },
  },
  {
    path: '/app/components/edit/:id',
    name: 'ComponentEdit',
    component: () => import('@/views/Admin/Components/Edit.vue'),
    meta: {
      pageTitle: 'Ayarlar',
      breadcrumb: [
        {
          text: 'Seo Yapılandırma',
          to: '/app/components',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_COMPONENTS',
      action: 'read',
    },
  },
]
